import "./style.css";
import React, { FormEvent, useEffect, useState } from "react";
import authHandler from "../../utils/handlers/authHandler";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsFillEnvelopeFill, BsPlusCircle } from "react-icons/bs";
import reportRequests from "../../utils/requests/reportRequests";
import ReportInterface from "../../interfaces/report";
import StatusBadge from "../../components/statusBadge";
import { reportStatus } from "../../enums/status";
import CriticallyBadge from "../../components/criticallyBadge";
import { reportCritically } from "../../enums/critically";
import AttachmentInterface from "../../interfaces/attachment";
import { ATTCHMENTS_ENDPOINTS } from "../../enums/apiEndpoints";
import exportReportRequest from "../../utils/requests/exportReportRequest";
import moment from "moment";
import {
  Center,
  FormErrorMessage,
  Input,
  Link,
  Textarea,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { routes } from "../../enums/routes";
import zipFiles from "../../utils/zipFiles";
import {
  Box,
  Button,
  Flex,
  Spacer,
  Text,
  Image,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormControl,
  Select,
  InputGroup,
} from "@chakra-ui/react";
import { userRoles } from "../../enums/roles";
import sessionControllerHandler from "../../utils/handlers/sessionControllerHandler";
import { reportVulnerability } from "../../enums/vulnerability";
import ProgramInterface from "../../interfaces/program";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { reasonForStatus } from "../../enums/reasonForStatus";
import ReasonForStatusBadge from "../../components/reasonForStatusBadge";
import chatRequests from "../../utils/requests/chatRequests";
import { RoomInterface } from "../../interfaces/room";
import filesRequests from "../../utils/requests/files.requests";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { ChatContext, ChatContextInterface } from "../../context/chatContext";
import { retestStatus } from "../../enums/retestStatus";

export default function ReportDetails() {
  let { id } = useParams();

  const navigate = useNavigate();
  const toast = useToast();
  const userRole = sessionControllerHandler.getUserRole();

  const { chatSelected, updateChatSelected } = React.useContext(
    ChatContext
  ) as ChatContextInterface;

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const [criticalValue, setCriticalValue] = useState(0);
  const [hightValue, setHightValue] = useState(0);
  const [mediumValue, setMediumValue] = useState(0);
  const [lowValue, setLowValue] = useState(0);
  const [reportValue, setReportValue] = useState("");

  const [reportValueHasError, setReportValueHasError] = useState(false);
  const [reportValueErrorMessage, setReportValueErrorMessage] = useState("");

  const [critically, setCritically] = useState("");
  const [vulnerability, setVulnerability] = useState("");
  const [developer, setDeveloper] = useState({ name: "", surname: "" });
  const [company, setCompany] = useState({ name: "", surname: "" });
  const [program, setProgram] = useState<ProgramInterface>();
  const [status, setStatus] = useState("");
  const [newReasonForStatus, setNewReasonForStatus] = useState<string | null>();
  const [title, setTitle] = useState("");
  const [operation, setOperation] = useState("");
  const [softwares, setSoftwares] = useState("");
  const [howToReproduce, setHowToReproduce] = useState("");
  const [possibleSolutions, setPossibleSolutions] = useState("");
  const [value, setValue] = useState<null | number>(null);
  const [solution, setSolution] = useState<null | string>(null);
  const [retestStatusValue, setRetestStatusValue] = useState<retestStatus>();
  const [newAttachments, setNewAttachments] = useState<File[]>([]);

  const [maxValueReport, setMaxValueReport] = useState(0);
  const [attachments, setAttachments] = useState<
    {
      file: any;
      name: string;
      type: string;
    }[]
  >([]);
  const [createdAt, setCreatedAt] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [imageToOpen, setImageToOpen] = useState<{
    file: any;
    name: string;
    type: string;
  }>();
  const [exportFormatType, setExportFormatType] = useState("");
  const [newReportStatus, setNewReportStatus] = useState("");
  const [newReportCritically, setNewReportCritically] = useState("");
  const [oldReasonForStatus, setOldReasonForStatus] = useState<string | null>(
    ""
  );
  const [statusChangeMessage, setStatusChangeMessage] = useState("");
  const [isManagerReportLoading, setIsManagerReportLoading] = useState(false);
  const [isExportReportLoading, setIsExportReportLoading] = useState(false);
  const [attachmentsDefault, setAttachmentsDefault] = useState<
    AttachmentInterface[]
  >([]);

  const zoomImageModal = useDisclosure();
  const exportReportModal = useDisclosure();
  const managerReportModal = useDisclosure();
  const retestStatusModal = useDisclosure();

  const defaultMaskOptions = {
    prefix: "R$ ",
    suffix: ".00",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: "",
    decimalLimit: 0,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  useEffect(() => {
    const authResponse = authHandler.checkUserAuthentication();
    setIsAuthenticated(authResponse);
    getReportDetail();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const newValue = getMaxValueReport(newReportCritically);
    setMaxValueReport(newValue);
  }, [newReportCritically]);

  const formatMoneyValue = (value: string) => {
    const defaultValue = 0;
    if (!value) return defaultValue;

    const formatedValue = value
      .replaceAll("R", "")
      .replaceAll("$", "")
      .replaceAll(" ", "")
      .replaceAll(",", "");

    const parsedValue: number = parseFloat(formatedValue);

    return parsedValue;
  };

  const getFile = async (url: string) => {
    const response = await filesRequests.get(url);

    if (
      response !== "Ocorreu um erro no servidor, tente novamente mais tarde"
    ) {
      return response;
    }

    return "";
  };

  const getReportDetail = async () => {
    setIsLoading(true);

    if (!id) {
      toast({
        title: "Relatório não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsLoading(false);
      return;
    }

    const response = await reportRequests.get(id);

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsLoading(false);
      return;
    }

    const reportDetails: ReportInterface = response;

    setCritically(reportDetails.critically);
    setDeveloper(reportDetails.developer);
    setCompany(reportDetails.company);
    setStatus(reportDetails.status);
    setTitle(reportDetails.title);
    setOperation(reportDetails.operation);
    setSoftwares(reportDetails.softwares);
    setHowToReproduce(reportDetails.howToReproduce);
    setPossibleSolutions(reportDetails.possibleSolutions);
    getAttachmentsFiles(reportDetails.attachments);
    setAttachmentsDefault(reportDetails.attachments);
    setCreatedAt(reportDetails.createdAt);
    setVulnerability(reportDetails.vulnerability);
    setProgram(reportDetails.program);
    setOldReasonForStatus(reportDetails.reasonForStatus);
    setIsLoading(false);
    setCriticalValue(reportDetails.criticalValue);
    setHightValue(reportDetails.hightValue);
    setMediumValue(reportDetails.mediumValue);
    setLowValue(reportDetails.lowValue);
    setValue(reportDetails.value ?? null);
    setSolution(reportDetails.solution ?? null);
    setRetestStatusValue(reportDetails.retestStatus);
  };

  const getMaxValueReport = (criticallyToGetValue: string) => {
    if (criticallyToGetValue == reportCritically.CRITICAL) {
      setReportValueErrorMessage("O valor máximo é " + criticalValue);

      return criticalValue;
    }

    if (criticallyToGetValue == reportCritically.HIGH) {
      setReportValueErrorMessage("O valor máximo é " + hightValue);

      return hightValue;
    }

    if (criticallyToGetValue == reportCritically.MEDIUM) {
      setReportValueErrorMessage("O valor máximo é " + mediumValue);

      return mediumValue;
    }

    if (criticallyToGetValue == reportCritically.LOW) {
      setReportValueErrorMessage("O valor máximo é " + lowValue);
      return lowValue;
    }

    return 0;
  };

  const getAttachmentsFiles = async (attachments: AttachmentInterface[]) => {
    let newAttachmentList = [];

    for (let attachment in attachments) {
      const newAttachment = attachments[attachment];

      const file = await getFile(
        process.env.REACT_APP_BASE_URL +
          ATTCHMENTS_ENDPOINTS.ATTACHMENT_GET +
          `/${newAttachment.id}`
      );

      newAttachmentList.push({
        file,
        name: newAttachment.name,
        type: newAttachment.mimetype,
      });
    }

    setAttachments(newAttachmentList);
  };

  const exportReport = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsExportReportLoading(true);

    if (exportFormatType === "CSV") {
      const csv = await exportReportToCSV();
      if (!csv) return;

      downloadFileFromUrl(csv);

      setIsExportReportLoading(false);
      return;
    }

    if (exportFormatType === "PDF") {
      const pdf = await exportReportToPDF();
      if (!pdf) return;

      console.log(pdf);

      downloadFileFromUrl(pdf, "_blank");

      setIsExportReportLoading(false);
      return;
    }

    if (exportFormatType === "ZIP") {
      await exportReportToZIP();

      setIsExportReportLoading(false);
      return;
    }
  };

  const exportReportToCSV = async () => {
    if (!id) {
      toast({
        title: "Não foi possível obter informações do relatório.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    const response = await exportReportRequest.csv({
      id,
      createdAt,
      title,
      operation,
      softwares,
      status: status as reportStatus,
      critically: critically as reportCritically,
      howToReproduce,
      possibleSolutions,
      company: `${company.name} ${company.surname}`,
      developer: `${developer.name} ${developer.surname}`,
      solution,
    });

    if (!response.reportUrl) {
      toast({
        title: "Não foi possível exportar relatório para CSV.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    return response;
  };

  const exportReportToPDF = async () => {
    if (!id) {
      toast({
        title: "Não foi possível obter informações do relatório.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    const response = await exportReportRequest.pdf({
      id,
      createdAt,
      title,
      operation,
      softwares,
      status: status as reportStatus,
      critically: critically as reportCritically,
      howToReproduce,
      possibleSolutions,
      company: `${company.name} ${company.surname}`,
      developer: `${developer.name} ${developer.surname}`,
      solution,
    });

    if (!response.reportUrl) {
      toast({
        title: "Não foi possível exportar relatório para PDF.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    return response;
  };

  const exportReportToZIP = async () => {
    let files: any = [];

    attachmentsDefault.forEach((attachment) => {
      files.push({
        name: attachment.name,
        url:
          process.env.REACT_APP_BASE_URL +
          ATTCHMENTS_ENDPOINTS.ATTACHMENT_GET +
          `/${attachment.id}`,
      });
    });

    const csv = await exportReportToCSV();

    if (!csv) return;

    files.push({
      name: csv.reportName,
      url: "http://" + csv.reportUrl,
    });

    const pdf = await exportReportToPDF();

    if (!pdf) return;

    files.push({
      name: pdf.reportName,
      url: "http://" + pdf.reportUrl,
    });

    await zipFiles(`${title}_${id}`, files);
    setIsExportReportLoading(false);
  };

  const downloadFileFromUrl = async (
    fileInformation: {
      reportName: string;
      reportUrl: string;
    },
    target?: string
  ) => {
    const url = await getFile("https://" + fileInformation.reportUrl);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileInformation.reportName);

    if (target) link.setAttribute("target", target);

    link.click();
    link.remove();
  };

  const managerReport = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let hasError = false;

    if (newReportStatus === reportStatus.ACCEPT) {
      hasError = reportValueValidate(reportValue, newReportCritically);
    }

    if (hasError) {
      return;
    }

    setIsManagerReportLoading(true);

    if (!id) {
      toast({
        title: "Relatório não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsManagerReportLoading(false);
      return;
    }

    const response = await reportRequests.update({
      id: id,
      title: title,
      operation: operation,
      softwares: softwares,
      howToReproduce: howToReproduce,
      possibleSolutions: possibleSolutions,
      critically: newReportCritically
        ? (newReportCritically as reportCritically)
        : (critically as reportCritically),
      status: newReportStatus as reportStatus,
      vulnerability: vulnerability as reportVulnerability,
      reasonForStatus: newReasonForStatus as reasonForStatus,
      value: reportValue ? formatMoneyValue(reportValue) : null,
      solution: solution ?? null,
      retestStatus: retestStatusValue,
    });

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      setIsManagerReportLoading(false);
      return;
    }

    setStatus(newReportStatus);
    setValue(reportValue ? formatMoneyValue(reportValue) : null);
    setOldReasonForStatus(newReasonForStatus as string | null);
    if (newReportCritically) setCritically(newReportCritically);

    setIsManagerReportLoading(false);
    managerReportModal.onClose();

    toast({
      title: "Salvo e enviado!",
      description: "Sua mensagem foi salva e enviada com sucesso!",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  const openChat = async () => {
    if (!id) return;

    const userRole = sessionControllerHandler.getUserRole();
    let userId: null | string = null;

    if (userRole == userRoles.GUEST) {
      userId = sessionControllerHandler.getUserID();
    }

    const response: any = await chatRequests.createRoom(
      id,
      userId ?? undefined
    );

    if (response.existentRoom) {
      const roomDetail = await chatRequests.getRoom(response.existentRoom.id);

      if (
        roomDetail &&
        roomDetail.roomUserDetail &&
        roomDetail.roomUserDetail.length
      ) {
        updateChatSelected(roomDetail.roomUserDetail[0]);
      }

      navigate(routes.MESSAGES);
      return;
    }

    if (!response.chat) {
      toast({
        title: "Não foi possível iniciar uma conversa.",
        description: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    const chatRoom: RoomInterface = response.chat;

    toast({
      title: "Conversa iniciada.",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  const reportValueValidate = (
    value: string | undefined,
    criticallyToGetValue: string
  ) => {
    if (value === "") {
      setReportValueHasError(false);

      return true;
    }

    if (!value) {
      setReportValueHasError(true);
      setReportValueErrorMessage("Insira o valor do relatório");

      return true;
    }

    const valueFormated = formatMoneyValue(value);
    const maxValue = getMaxValueReport(criticallyToGetValue);

    if (valueFormated > maxValue) {
      setReportValueHasError(true);
      return true;
    }

    setReportValueHasError(false);
    return false;
  };

  const selectAttachment = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute(
      "accept",
      ".png, .jpg, .jpeg, video/mp4, video/x-m4v, video/*"
    );

    input.addEventListener("change", () => {
      if (input.files && input.files?.length !== 0) {
        includeAttachment(input.files[0]);
      }
      input.remove();
    });

    input.click();
  };

  const includeAttachment = (newAttachment: File) => {
    setNewAttachments([...newAttachments, ...[newAttachment]]);
  };

  const updateRetestStatus = async (newStatus: retestStatus) => {
    if (!id) {
      toast({
        title: "Relatório não identificado.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    const response = await reportRequests.update({
      id: id,
      title: title,
      operation: operation,
      softwares: softwares,
      howToReproduce: howToReproduce,
      possibleSolutions: possibleSolutions,
      critically: newReportCritically
        ? (newReportCritically as reportCritically)
        : (critically as reportCritically),
      status: status as reportStatus,
      vulnerability: vulnerability as reportVulnerability,
      reasonForStatus: newReasonForStatus as reasonForStatus,
      value: reportValue ? formatMoneyValue(reportValue) : null,
      solution: solution ?? null,
      retestStatus: newStatus,
    });

    if (!response.id) {
      toast({
        title: response,
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    setNewAttachments([]);
    retestStatusModal.onClose();
    window.location.reload();

    toast({
      title: "Salvo e enviado!",
      description: "O status de retest foi atualizado!",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  const insertNewAttachments = async () => {
    for (let propertie in newAttachments) {
      const newAttachment = newAttachments[propertie];

      const uploadStatus = await reportRequests.uploadAttachments(
        id,
        newAttachment
      );

      if (uploadStatus !== "Anexos adicionados") {
        toast({
          title: "Não foi possível adicionar o anexo " + newAttachment.name,
          description: uploadStatus,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            h="70vh"
          >
            <Spinner size="xl" color="teal.500" />
          </Flex>
        ) : (
          <>
            <Flex
              gap="1rem"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
            >
              <Box
                as={BsArrowLeft}
                size="1.5rem"
                color="gray.700"
                cursor="pointer"
                onClick={() => navigate(routes.REPORTS)}
              />

              <Flex
                gap="0.3rem"
                maxWidth="20%"
                flexDirection="row"
                alignItems="center"
                cursor="default"
              >
                <Text color="gray.700" fontSize="sm" as="b">
                  Pesquisador:
                </Text>
                <Tooltip
                  label={`${developer.name} ${developer.surname}`}
                  placement="bottom"
                  hasArrow
                >
                  <Text
                    color="gray.700"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    fontSize="sm"
                  >
                    {`${developer.name} ${developer.surname}`}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex
                gap="0.3rem"
                flexDirection="row"
                alignItems="center"
                cursor="default"
              >
                <Text color="gray.700" fontSize="sm" as="b">
                  Status:
                </Text>

                <Text color="gray.700" fontSize="sm">
                  <StatusBadge status={status as reportStatus} />
                </Text>
              </Flex>

              <Flex
                gap="0.3rem"
                flexDirection="row"
                alignItems="center"
                cursor="default"
              >
                <Text color="gray.700" fontSize="sm" as="b">
                  Criticidade:
                </Text>

                <Text color="gray.700" fontSize="sm">
                  <CriticallyBadge
                    critically={critically as reportCritically}
                  />
                </Text>
              </Flex>

              <Spacer />

              <Box
                as={BsFillEnvelopeFill}
                size="1.5rem"
                color="teal.500"
                cursor="pointer"
                onClick={() => openChat()}
              />

              {/* <Button
                colorScheme="teal"
                variant="outline"
                onClick={exportReportModal.onOpen}
              >
                Exportar
              </Button> */}

              {(userRole === userRoles.COMPANY ||
                userRole === userRoles.GUEST) &&
                status != reportStatus.ACCEPT &&
                status != reportStatus.NOT_ACCEPT && (
                  <Button
                    colorScheme="teal"
                    variant="solid"
                    onClick={managerReportModal.onOpen}
                  >
                    Gerenciar
                  </Button>
                )}

              {/* {(userRole === userRoles.COMPANY ||
                userRole === userRoles.GUEST) &&
                retestStatusValue === retestStatus.UNSOLICITED && (
                  <Button
                    colorScheme="teal"
                    variant="solid"
                    onClick={() => {
                      updateRetestStatus(retestStatus.SOLICITED);
                    }}
                  >
                    Solicitar retest
                  </Button>
                )}

              {userRole === userRoles.RESEARCHER &&
                retestStatusValue != retestStatus.SOLICITED && (
                  <Button
                    colorScheme="teal"
                    variant="solid"
                    onClick={retestStatusModal.onOpen}
                  >
                    Concluir retest
                  </Button>
                )} */}

              {/* {userRole === userRoles.RESEARCHER && (
                <Button
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => navigate(routes.REPORT_EDIT + id)}
                >
                  Editar
                </Button>
              )} */}
            </Flex>

            <Flex
              borderRadius="1rem"
              mt="1.5rem"
              p="2rem"
              flexDirection="column"
              gap="1rem"
              bg="white"
              overflowY="auto"
            >
              {program && (
                <Text color="gray.700" fontSize="sm">
                  <b>Origem do relatório / Programa: </b>
                  <Link
                    as={NavLink}
                    isExternal
                    to={routes.PROGRAM_DETAILS + program.id}
                  >
                    {program.title} <ExternalLinkIcon mx="2px" />
                  </Link>
                </Text>
              )}

              <Text color="gray.700" fontSize="sm">
                <b>Registrado em: </b>
                {moment(new Date(createdAt)).format("LLL")}
              </Text>

              <Text color="gray.700" fontSize="sm">
                <b>Título do Relatório: </b>
                {title}
              </Text>

              <Text color="gray.700" fontSize="sm">
                <b>Funcionamento: </b>
                {operation}
              </Text>

              <Text color="gray.700" fontSize="sm">
                <b>Softwares utilizados: </b>
                {softwares}
              </Text>

              <Text color="gray.700" fontSize="sm">
                <b>Como reproduzir: </b>
                {howToReproduce}
              </Text>

              <Text color="gray.700" fontSize="sm">
                <b>Informações adicionais: </b>
                {possibleSolutions}
              </Text>

              {solution && (
                <Text color="gray.700" fontSize="sm">
                  <b>Solução: </b>
                  {solution}
                </Text>
              )}

              <Text color="gray.700" fontSize="sm">
                <b>Criticidade: </b>
                <CriticallyBadge critically={critically as reportCritically} />
              </Text>

              <Text color="gray.700" fontSize="sm">
                <b>Vulnerabilidade: </b>
                {vulnerability}
              </Text>

              {value ? (
                <Text color="gray.700" fontSize="sm">
                  <b>Valor: </b>
                  {`R$ ${value}.00`}
                </Text>
              ) : (
                ""
              )}

              <Text color="gray.700" fontSize="sm">
                {oldReasonForStatus ? (
                  <>
                    <b>Motivo do status: </b>{" "}
                    {
                      <ReasonForStatusBadge
                        status={oldReasonForStatus as reasonForStatus}
                      />
                    }
                  </>
                ) : (
                  <>
                    <b>Motivo do status: </b> {"Nenhum motivo cadastrado."}
                  </>
                )}
              </Text>

              <Flex flexDirection="column" gap="1rem">
                <Text color="gray.700" fontSize="sm">
                  <b>Anexos:</b>
                  {attachments.length === 0 && " Nenhum anexo encontrado."}
                </Text>
                <Flex flexDirection="row" gap="1rem">
                  {attachments.map((attachment, index) =>
                    attachment.type.includes("video") ? (
                      <video
                        key={index}
                        src={attachment.file}
                        title={attachment.name}
                        controls
                        controlsList="nodownload"
                        width="100"
                        height="100"
                        className="video"
                      />
                    ) : (
                      <Image
                        key={index}
                        src={attachment.file}
                        alt={"Imagem " + attachment.name}
                        boxSize="100px"
                        objectFit="cover"
                        borderRadius="8px"
                        title={attachment.name}
                        fallbackSrc="https://via.placeholder.com/150"
                        onClick={() => {
                          setImageToOpen(attachment);
                          zoomImageModal.onOpen();
                        }}
                        cursor="pointer"
                      />
                    )
                  )}
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </>

      <Modal
        size="full"
        isOpen={zoomImageModal.isOpen}
        onClose={zoomImageModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody m="0" p="2.5rem">
            <Flex justifyContent="center" h="80vh">
              {imageToOpen && (
                <Image
                  src={imageToOpen.file}
                  alt={"Imagem " + imageToOpen?.name}
                  borderRadius="8px"
                  fallbackSrc="https://via.placeholder.com/150"
                  w="auto"
                  h="100%"
                  objectFit="contain"
                />
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        size="xs"
        isOpen={exportReportModal.isOpen}
        onClose={exportReportModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <form
            onSubmit={(event) => {
              exportReport(event);
            }}
          >
            <ModalHeader>
              <Text color="gray.700" size="lg">
                Exportar
              </Text>
            </ModalHeader>

            <ModalBody>
              <Flex>
                <Text color="gray.700" size="sm">
                  Formato:
                </Text>
                <Text color="red.500" size="sm">
                  *
                </Text>
              </Flex>

              <FormControl mt="0.5rem" isRequired>
                <Select
                  placeholder="Selecione"
                  focusBorderColor="teal.500"
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                  color="gray.400"
                  w="100%"
                  onChange={(event: any) =>
                    setExportFormatType(event.target.value)
                  }
                >
                  <option value="PDF">PDF</option>
                  {/* <option value="CSV">CSV</option>
                  <option value="ZIP">ZIP</option> */}
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="teal"
                variant="outline"
                mr="1rem"
                w="100%"
                onClick={exportReportModal.onClose}
              >
                Cancelar
              </Button>

              <Button
                isLoading={isExportReportLoading}
                type="submit"
                colorScheme="teal"
                variant="solid"
                w="100%"
              >
                Exportar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        size="xl"
        isOpen={managerReportModal.isOpen}
        onClose={managerReportModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <form
            onSubmit={(event) => {
              managerReport(event);
            }}
          >
            <ModalHeader>
              <Text color="gray.700" size="lg">
                Gerenciar
              </Text>
            </ModalHeader>

            <ModalBody>
              <Flex>
                <Text color="gray.700" size="sm">
                  Status:
                </Text>
                <Text color="red.500" size="sm">
                  *
                </Text>
              </Flex>

              <FormControl mt="0.5rem" isRequired>
                <Select
                  placeholder="Selecione"
                  focusBorderColor="teal.500"
                  borderColor="gray.400"
                  size="lg"
                  variant="outline"
                  color="gray.400"
                  w="100%"
                  onChange={(event: any) => {
                    setNewReportStatus(event.target.value);
                    setNewReasonForStatus(null);
                    setNewReportCritically("");
                    setReportValue("");
                    reportValueValidate("", "");
                    setStatusChangeMessage("");
                  }}
                  value={newReportStatus}
                >
                  <option value={reportStatus.NEW}>Novo</option>
                  <option value={reportStatus.ACCEPT}>Aceito</option>
                  <option value={reportStatus.NOT_ACCEPT}>Não aceito</option>
                  <option value={reportStatus.MORE_INFORMATION}>
                    Mais informações
                  </option>
                  <option value={reportStatus.IN_ANALYSIS}>Em análise</option>
                </Select>
              </FormControl>

              {newReportStatus === reportStatus.ACCEPT ? (
                <>
                  <Flex mt="1rem">
                    <Text color="gray.700" size="sm">
                      Confirme a criticidade do relatório:
                    </Text>
                    <Text color="red.500" size="sm">
                      *
                    </Text>
                  </Flex>

                  <FormControl mt="0.5rem" isRequired>
                    <Select
                      placeholder="Selecione"
                      focusBorderColor="teal.500"
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      color="gray.400"
                      w="100%"
                      onChange={(event: any) => {
                        setNewReportCritically(event.target.value);
                        setReportValue("");
                        reportValueValidate("", event.target.value);
                      }}
                      value={newReportCritically}
                    >
                      <option value={reportCritically.CRITICAL}>Crítico</option>
                      <option value={reportCritically.HIGH}>Alto</option>
                      <option value={reportCritically.MEDIUM}>Médio</option>
                      <option value={reportCritically.LOW}>Baixo</option>
                    </Select>
                  </FormControl>

                  {newReportCritically !== "" ? (
                    <>
                      <Flex mt="1rem">
                        <Text color="gray.700" size="sm">
                          Insira o valor do relatório (Máximo até R${" "}
                          {maxValueReport}.00):
                        </Text>
                        <Text color="red.500" size="sm">
                          *
                        </Text>
                      </Flex>

                      <FormControl
                        mt="0.5rem"
                        isRequired
                        isInvalid={reportValueHasError}
                      >
                        <InputGroup>
                          <Input
                            placeholder="Insira o valor do relatório"
                            focusBorderColor="teal.500"
                            borderColor="gray.400"
                            size="lg"
                            variant="outline"
                            color="gray.400"
                            w="100%"
                            onChange={(event: any) => {
                              setReportValue(event.target.value);
                              reportValueValidate(
                                event.target.value,
                                newReportCritically
                              );
                            }}
                            value={reportValue}
                            as={MaskedInput}
                            mask={currencyMask}
                          />
                        </InputGroup>

                        {reportValueHasError ? (
                          <Flex flexDirection="column" alignItems="center">
                            <FormErrorMessage textDecoration="underline">
                              {reportValueErrorMessage}
                            </FormErrorMessage>
                          </Flex>
                        ) : (
                          " "
                        )}
                      </FormControl>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {newReportStatus === reportStatus.NOT_ACCEPT ? (
                <>
                  <Flex mt="1rem">
                    <Text color="gray.700" size="sm">
                      Motivo do status:
                    </Text>
                    <Text color="red.500" size="sm">
                      *
                    </Text>
                  </Flex>

                  <FormControl mt="0.5rem" isRequired>
                    <Select
                      placeholder="Selecione"
                      focusBorderColor="teal.500"
                      borderColor="gray.400"
                      size="lg"
                      variant="outline"
                      color="gray.400"
                      w="100%"
                      onChange={(event: any) =>
                        setNewReasonForStatus(event.target.value)
                      }
                      value={newReasonForStatus ?? ""}
                    >
                      <option value={reasonForStatus.DUPLICATE}>
                        Duplicado
                      </option>
                      <option value={reasonForStatus.OUT_OF_SCOPE}>
                        Fora de escopo
                      </option>
                      <option value={reasonForStatus.SPAM}>Spam</option>
                      <option value={reasonForStatus.FALSE_POSITIVE}>
                        Falso positivo
                      </option>
                    </Select>
                  </FormControl>
                </>
              ) : (
                ""
              )}

              {newReportStatus !== "" &&
              newReportStatus !== reportStatus.NOT_ACCEPT &&
              newReportStatus !== reportStatus.ACCEPT ? (
                <>
                  <Flex mt="1rem">
                    <Text color="gray.700" size="sm">
                      Mensagem:
                    </Text>
                    <Text color="red.500" size="sm">
                      *
                    </Text>
                  </Flex>

                  <FormControl mt="0.5rem" isRequired>
                    <Textarea
                      h="15rem"
                      onChange={(event: any) =>
                        setStatusChangeMessage(event.target.value)
                      }
                      placeholder="Digite aqui"
                      value={statusChangeMessage ?? ""}
                      focusBorderColor="teal.500"
                    />
                  </FormControl>
                </>
              ) : (
                ""
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="teal"
                variant="outline"
                mr="1rem"
                onClick={managerReportModal.onClose}
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                colorScheme="teal"
                variant="solid"
                isLoading={isManagerReportLoading}
              >
                Salvar e enviar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        size="xl"
        isOpen={retestStatusModal.isOpen}
        onClose={retestStatusModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <form>
            <ModalHeader>
              <Text color="gray.700" size="lg">
                Concluir retest
              </Text>
            </ModalHeader>

            <ModalBody>
              <Flex>
                <Text color="gray.700" size="sm">
                  Evidências:
                </Text>
              </Flex>

              <Flex
                border="1px"
                borderColor="gray.400"
                borderRadius="0.3rem"
                p="0.6rem"
                gap="0.6rem"
              >
                {newAttachments.map((newAttachment, index) =>
                  newAttachment.type.includes("image") ? (
                    <Image
                      key={index}
                      src={URL.createObjectURL(newAttachment)}
                      alt={"Imagem " + newAttachment.name}
                      boxSize="4.5rem"
                      objectFit="cover"
                      borderRadius="0.3rem"
                      borderColor="gray.400"
                      title={newAttachment.name}
                      fallbackSrc="https://via.placeholder.com/150"
                      cursor="pointer"
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(newAttachment)}
                      title={newAttachment.name}
                      controls
                      controlsList="nodownload"
                      className="video"
                    />
                  )
                )}

                <Center
                  w="4.5rem"
                  h="4.5rem"
                  border="1px"
                  borderColor="gray.400"
                  borderRadius="0.3rem"
                >
                  <Box
                    as={BsPlusCircle}
                    size="1.5rem"
                    color="gray.400"
                    cursor="pointer"
                    onClick={() => selectAttachment()}
                    _hover={{ color: "gray.600" }}
                  />
                </Center>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="teal"
                variant="outline"
                mr="1rem"
                onClick={() => {
                  setNewAttachments([]);
                  retestStatusModal.onClose();
                }}
              >
                Cancelar
              </Button>

              <Button
                colorScheme="teal"
                variant="solid"
                onClick={() => {
                  insertNewAttachments();
                  updateRetestStatus(retestStatus.CORRECTED);
                }}
              >
                Salvar e enviar
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
